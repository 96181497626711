import { Root, Slottable } from "@radix-ui/react-slot";
import classNames from "classnames";
import React from "react";

const classNamePrefix = "Button";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  asChild?: boolean;
  fluid?: boolean;
  icon?:
    | "cancel"
    | "download"
    | "link"
    | "next"
    | "previous"
    | "search"
    | "see"
    | "send";
  isSubmitting?: boolean;
  level?: "primary" | "secondary";
  spinnerLabel?: string;
  variant?: "action" | "text";
};

export const Button = React.forwardRef(
  (props: ButtonProps, ref?: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      asChild,
      children,
      className,
      disabled,
      fluid,
      icon,
      isSubmitting,
      level,
      spinnerLabel = "Merci de patienter…",
      variant,
      ...restProps
    } = props;
    const Comp = asChild ? Root : "button";
    return (
      <Comp
        ref={ref}
        {...restProps}
        className={classNames(className, classNamePrefix)}
        data-fluid={fluid}
        data-icon={icon}
        data-is-submitting={isSubmitting}
        data-level={level}
        data-variant={variant}
        disabled={isSubmitting ? isSubmitting : disabled}
      >
        {isSubmitting && (
          <span
            aria-label={spinnerLabel}
            className={`${classNamePrefix}-spinner`}
            role="status"
          />
        )}
        <Slottable>{children}</Slottable>
      </Comp>
    );
  },
);
