import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { router } from "../../app/services/router";
import { Button } from "../shared/Button";
import { SignUpMarkdown } from "./SignUpMarkdown";
import { SignUpState } from "./SignUpState";

type SignUpResultProps = {
  children: string;
  session: { email?: string; username?: string };
  onChange: React.Dispatch<React.SetStateAction<SignUpState>>;
};

export const SignUpResult = (props: SignUpResultProps) => {
  const { children, session, onChange } = props;

  const { t } = useTranslation();
  const { cancel } = t("features.account.SignUpResult") as unknown as {
    cancel: { text: string };
  };

  return (
    <div role="alert">
      <SignUpMarkdown session={session} onChange={onChange}>
        {children}
      </SignUpMarkdown>
      <Button
        asChild
        dataLayer={{
          eventAction: "clic",
          eventCategory: "pre-home",
          eventLabel: "connexion",
        }}
        variant="action"
      >
        <Link to={router.getTo("home")}>{cancel.text}</Link>
      </Button>
    </div>
  );
};
