import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { router } from "../app/services/router";
import { useAppSelector } from "../app/store";
import { selectCurrentUser } from "../app/store/auth";
import { A } from "../features/shared/A";
import { Dialog } from "../features/shared/Dialog";

import { ReactComponent as Icon } from "./SitemapPageIcon.svg";

export const SitemapPage = () => {
  const hash = router.getTo("sitemap");

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Dialog
      open={hash === location.hash}
      onOpenChange={(open) => {
        if (!open) {
          navigate({ ...location, hash: "" });
        }
      }}
    >
      <SitemapPageContent
        trigger={
          document.querySelector(`[href*="${hash}"]`) as HTMLAnchorElement
        }
      />
    </Dialog>
  );
};

type SitemapPageContentProps = { trigger: HTMLAnchorElement };

const SitemapPageContent = (props: SitemapPageContentProps) => {
  const { trigger } = props;

  const currentUser = useAppSelector(selectCurrentUser);

  const { t } = useTranslation();
  const { meta, navs, title } = t("pages.SitemapPage") as unknown as {
    meta: { title: string };
    navs: {
      appendices: {
        links: Record<
          | "accessibility"
          | "fraudAlert"
          | "legalNotice"
          | "protectionPolicy"
          | "sitemap"
          | "termsOfService",
          { href?: string; text: string }
        >;
        title: string;
      };
      authenticated: {
        links: Record<
          | "home"
          | "mainAccount"
          | "mainAddContracts"
          | "mainContact"
          | "mainMessaging"
          | "mainOffers",
          { text: string }
        >;
        title: string;
      };
      unauthenticated: {
        links: Record<
          "authenticationForgotPassword" | "authenticationSignUp" | "home",
          { text: string }
        >;
        title: string;
      };
    };
    title: string;
  };

  return (
    <Dialog.Content
      dataLayer={{
        categorie_page: "plan_du_site",
        nom_page: "plan_du_site",
        virtualpagetitle: "Plan du site",
      }}
      icon={<Icon />}
      meta={meta}
      title={title}
      onCloseAutoFocus={() => trigger.focus()}
    >
      {[
        {
          key: "unauthenticated",
          ...navs.unauthenticated,
          links: [
            {
              key: "home",
              ...navs.unauthenticated.links.home,
              href: router.getTo("home"),
              visible: true,
            },
            {
              key: "authenticationSignUp",
              ...navs.unauthenticated.links.authenticationSignUp,
              href: router.getTo("authenticationSignUp"),
              visible: true,
            },
            {
              key: "authenticationForgotPassword",
              ...navs.unauthenticated.links.authenticationForgotPassword,
              href: router.getTo("authenticationForgotPassword"),
              visible: true,
            },
          ],
          visible: !currentUser,
        },
        {
          key: "authenticated",
          ...navs.authenticated,
          links: [
            {
              key: "home",
              ...navs.authenticated.links.home,
              href: router.getTo("home"),
              visible: true,
            },
            {
              key: "mainMessaging",
              ...navs.authenticated.links.mainMessaging,
              href: router.getTo("mainMessaging"),
              visible: currentUser?.links.messaging,
            },
            {
              key: "mainOffers",
              ...navs.authenticated.links.mainOffers,
              href: router.getTo("mainOffers"),
              visible: true,
            },
            {
              key: "mainContact",
              ...navs.authenticated.links.mainContact,
              href: router.getTo("mainContact"),
              visible: true,
            },
            {
              key: "mainAccount",
              ...navs.authenticated.links.mainAccount,
              href: router.getTo("mainAccount"),
              visible: true,
            },
            {
              key: "mainAddContracts",
              ...navs.authenticated.links.mainAddContracts,
              href: router.getTo("mainAddContracts"),
              visible: true,
            },
          ],
          visible: currentUser,
        },
        {
          key: "appendices",
          ...navs.appendices,
          links: [
            {
              key: "termsOfService",
              ...navs.appendices.links.termsOfService,
              href: router.getTo("termsOfService"),
              visible: true,
            },
            {
              key: "legalNotice",
              ...navs.appendices.links.legalNotice,
              href: router.getTo("legalNotice"),
              visible: true,
            },
            {
              key: "sitemap",
              ...navs.appendices.links.sitemap,
              href: router.getTo("sitemap"),
              visible: true,
            },
            {
              key: "protectionPolicy",
              ...navs.appendices.links.protectionPolicy,
              href: navs.appendices.links.protectionPolicy.href as string,
              visible: true,
            },
            {
              key: "fraudAlert",
              ...navs.appendices.links.fraudAlert,
              href: navs.appendices.links.fraudAlert.href as string,
              visible: true,
            },
            {
              key: "accessibility",
              ...navs.appendices.links.accessibility,
              href: router.getTo("accessibility"),
              visible: true,
            },
          ],
          visible: true,
        },
      ].map((nav) =>
        nav.visible ? (
          <nav key={nav.key}>
            <h2>{nav.title}</h2>
            <ul>
              {nav.links.map((link) =>
                link.visible ? (
                  <li key={link.key}>
                    <A href={link.href}>{link.text}</A>
                  </li>
                ) : null,
              )}
            </ul>
          </nav>
        ) : null,
      )}
    </Dialog.Content>
  );
};
