import classNames from "classnames";
import React from "react";

const classNamePrefix = "Logo";

type LogoProps = React.HTMLAttributes<HTMLDivElement>;

export const Logo = React.forwardRef(
  (props: LogoProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const { className, ...restProps } = props;
    return (
      <div
        ref={ref}
        {...restProps}
        className={classNames(className, classNamePrefix)}
      />
    );
  },
);
