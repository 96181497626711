import { appApi } from "../../app/api/public/app";
import { AppConfig } from "../../app/models/AppConfig";
import { A } from "../shared/A";
import { Ad } from "../shared/Ad";

export const BannersLandscape = () => {
  const {
    currentData: { banner },
  } = appApi.endpoints.getAppConfig.useQueryState() as {
    currentData: AppConfig;
  };

  return banner ? (
    <Ad style={{ background: banner.background }} variant="landscape">
      <A
        aria-label={banner.label}
        dataLayer={{
          eventAction: "clic",
          eventCategory: "pre-home",
          eventLabel: banner.label,
        }}
        href={banner.url}
      >
        <img alt="" loading="lazy" src={banner.desktop} />
      </A>
    </Ad>
  ) : null;
};
