export const routes = {
  home: "/",
  accessibility: "#accessibilite",
  legalNotice: "#mentions-legales",
  sitemap: "#plan-du-site",
  termsOfService: "#conditions-generales-d-utilisation",

  authenticationForgotPassword: "/mot-de-passe-oublie",
  authenticationSignUp: "/je-cree-mon-espace",

  mainAccount: "/mon-profil/*",
  mainAccountProfile: "/mon-profil",
  mainAccountSettings: "/mon-profil/parametres-du-compte",
  mainAddContracts: "/ajouter-un-contrat/*",
  mainAddContractsContract: "/ajouter-un-contrat/:contractActivationReference",
  mainContact: "/aide-et-contact/*",
  mainContactInterlocutors: "/aide-et-contact/interlocuteurs-privileges/*",
  mainContactInterlocutorsInterlocutorGroup:
    "/aide-et-contact/interlocuteurs-privileges/:contractActivationReference",
  mainContractsCommercialLease:
    "/mes-biens/bail-commercial/:contractActivationReference/*",
  mainContractsCommercialLeaseDetails:
    "/mes-biens/bail-commercial/:contractActivationReference",
  mainContractsCommercialLeaseDocuments:
    "/mes-biens/bail-commercial/:contractActivationReference/mes-documents",
  mainContractsManagement:
    "/mes-biens/mandat-de-gestion/:contractActivationReference/*",
  mainContractsManagementAccountStatus:
    "/mes-biens/mandat-de-gestion/:contractActivationReference/ma-situation-de-compte",
  mainContractsManagementDocuments:
    "/mes-biens/mandat-de-gestion/:contractActivationReference/mes-documents",
  mainContractsManagementStatus:
    "/mes-biens/mandat-de-gestion/:contractActivationReference/etat-locatif-de-mon-bien",
  mainContractsManagementTaxReturn:
    "/mes-biens/mandat-de-gestion/:contractActivationReference/declaration-fiscale",
  mainContractsNew:
    "/mes-biens/achat-dans-le-neuf/:contractActivationReference/*",
  mainContractsNewAccountStatus:
    "/mes-biens/achat-dans-le-neuf/:contractActivationReference/ma-situation-de-compte",
  mainContractsNewConstructionSite:
    "/mes-biens/achat-dans-le-neuf/:contractActivationReference/photos-de-chantier/*",
  mainContractsNewConstructionSitePicture:
    "/mes-biens/achat-dans-le-neuf/:contractActivationReference/photos-de-chantier/:pictureId",
  mainContractsNewDetails:
    "/mes-biens/achat-dans-le-neuf/:contractActivationReference/mon-acquisition",
  mainContractsNewDocuments:
    "/mes-biens/achat-dans-le-neuf/:contractActivationReference/mes-documents",
  mainContractsNewInside:
    "/mes-biens/achat-dans-le-neuf/:contractActivationReference/visite-et-personnalisation-de-mon-bien",
  mainContractsNewProgress:
    "/mes-biens/achat-dans-le-neuf/:contractActivationReference",
  mainContractsRent: "/mes-biens/location/:contractActivationReference/*",
  mainContractsRentAccountStatus:
    "/mes-biens/location/:contractActivationReference/ma-situation-de-compte",
  mainContractsRentDetails: "/mes-biens/location/:contractActivationReference",
  mainContractsRentDocuments:
    "/mes-biens/location/:contractActivationReference/mes-documents",
  mainContractsRentPayments:
    "/mes-biens/location/:contractActivationReference/mes-paiements/*",
  mainContractsRentPaymentsAutomatic:
    "/mes-biens/location/:contractActivationReference/mes-paiements/adhesion-au-prelevement-automatique",
  mainContractsRentPaymentsPunctual:
    "/mes-biens/location/:contractActivationReference/mes-paiements/paiement-ponctuel",
  mainContractsTrustee:
    "/mes-biens/mandat-de-syndic/:contractActivationReference/*",
  mainContractsTrusteeData:
    "/mes-biens/mandat-de-syndic/:contractActivationReference/mes-informations",
  mainContractsTrusteeDetails:
    "/mes-biens/mandat-de-syndic/:contractActivationReference/ma-copropriete",
  mainContractsTrusteeDocuments:
    "/mes-biens/mandat-de-syndic/:contractActivationReference/mes-documents",
  mainContractsTrusteeEConvocationSubscribe:
    "/mes-biens/mandat-de-syndic/:contractActivationReference/inscription-e-convocation",
  mainContractsTrusteeEConvocationUnsubscribe:
    "/mes-biens/mandat-de-syndic/:contractActivationReference/desinscription-e-convocation",
  mainContractsTrusteeGeneralMeetings:
    "/mes-biens/mandat-de-syndic/:contractActivationReference/mon-assemblee-generale",
  mainContractsTrusteePaymentsAutomatic:
    "/mes-biens/mandat-de-syndic/:contractActivationReference/adhesion-au-prelevement-automatique",
  mainMessaging: "/ma-messagerie/*",
  mainMessagingDemand: "/ma-messagerie/:demandId",
  mainOffers: "/j-ai-un-nouveau-besoin",
};

export type RouteKey = keyof typeof routes;
