import { useTranslation } from "react-i18next";

import { router } from "../../app/services/router";
import { A } from "../shared/A";
import { Logo } from "../shared/Logo";

export const AppLogo = () => {
  const { t } = useTranslation();
  const { label } = t("features.app.AppLogo") as unknown as { label: string };

  return (
    <Logo>
      <A aria-label={label} href={router.getTo("home")}>
        <img alt="" height="52" src="/app.svg" width="180" />
      </A>
    </Logo>
  );
};
