import classNames from "classnames";
import React from "react";

const classNamePrefix = "Ad";

type AdProps = React.HTMLAttributes<HTMLDivElement> & {
  variant?: "banner" | "landscape";
};

export const Ad = React.forwardRef(
  (props: AdProps, ref?: React.ForwardedRef<HTMLDivElement>) => {
    const { className, variant, ...restProps } = props;
    return (
      <div
        ref={ref}
        {...restProps}
        className={classNames(className, classNamePrefix)}
        data-variant={variant}
      />
    );
  },
);
