import { Auth } from "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import axios from "axios";

import { publicApi } from ".";
import { AppConfig } from "../../models/AppConfig";
import { HealthCheck } from "../../models/HealthCheck";
import { apiKeys } from "../../services/apiKeys";
import { session } from "../../services/session";

export const appApi = publicApi.injectEndpoints({
  endpoints: (builder) => ({
    getAppConfig: builder.query<AppConfig, void>({
      queryFn: async (_arg, _queryLifecycleApi, _extraOptions, fetch) => {
        const response = await fetch({ url: "/config", method: "GET" });
        if (response.error) {
          return { error: response.error };
        }

        const appConfig = response.data as AppConfig;
        apiKeys.configure(appConfig.apiKeys);
        Amplify.configure(appConfig.cognito);
        return { data: appConfig };
      },
    }),
    getHealthCheck: builder.query<HealthCheck, void>({
      query: () => ({ url: "/health-check", method: "GET" }),
    }),
    getMaintenance: builder.query<{ enabled: boolean }, void>({
      queryFn: async () => {
        const response = await axios({
          url: `/maintenance.json?v=${Date.now()}`,
        });

        if (response.data.enabled) {
          try {
            await Auth.currentAuthenticatedUser();
            session.signOut();
          } catch {}
        }

        return { data: response.data };
      },
    }),
  }),
});

export const { useGetHealthCheckQuery, useGetMaintenanceQuery } = appApi;
